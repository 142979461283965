

import {
    PEDIDOS_LIST
    , PEDIDOS_ISLOADING

    , PEDIDOS_LIST_NFE
    , PEDIDOS_ISLOADING_NFE
    , PEDIDOS_LIST_RASTREIO
    , PEDIDOS_ISLOADING_RASTREIO
} from '../actions/ActionsTypes';

const INITIAL_STATE = {
    dados: []
    , dados_rastreio: []
    , isloading: true
    , isloading_rastreio: true
    , xmlnfe: null
    , isloadingnfe: false
}
//eslint-disable-next-line 
export default (state = INITIAL_STATE, action) => {
    switch (action.type) {

        case PEDIDOS_LIST:
            return { ...state, dados: action.payload }


        case PEDIDOS_LIST_RASTREIO:
            return { ...state, dados_rastreio: action.payload }

        case PEDIDOS_ISLOADING_RASTREIO:
            return { ...state, isloading_rastreio: action.payload }

        case PEDIDOS_ISLOADING:
            return { ...state, isloading: action.payload }

        case PEDIDOS_LIST_NFE:
            return { ...state, xmlnfe: action.payload }

        case PEDIDOS_ISLOADING_NFE:
            return { ...state, isloadingnfe: action.payload }

        default:
            return state;
    }
}