export const PEDIDOS_LIST = "PEDIDOS_LIST";
export const PEDIDOS_LIST_RASTREIO = "PEDIDOS_LIST_RASTREIO";
export const PEDIDOS_ISLOADING = "PEDIDOS_ISLOADING";
export const PEDIDOS_ISLOADING_RASTREIO = "PEDIDOS_ISLOADING_RASTREIO";
export const PEDIDOS_LIST_NFE = "PEDIDOS_LIST_NFE";
export const PEDIDOS_ISLOADING_NFE = "PEDIDOS_ISLOADING_NFE";



export const ENTREGACERTA_DETALHES_NF = "ENTREGACERTA_DETALHES_NF";
export const ENTREGACERTA_DETALHES_NF_LOADING = "ENTREGACERTA_DETALHES_NF_LOADING";
export const ENTREGACERTA_SCREEN = "ENTREGACERTA_SCREEN";
export const ENTREGACERTA_SAVE_LOADING = "ENTREGACERTA_SAVE_LOADING";
