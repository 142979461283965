import React from "react";
import { BrowserView, MobileView } from 'react-device-detect';
import '../../styles/formFooter.css';

function Footer() {
    return (
        <>
            <BrowserView>
                <footer className="footer">
                    <div className="footer-container">
                    </div>
                </footer>
            </BrowserView>

            <MobileView>
                <footer className="footer">
                    <div className="footer-container">
                    </div>
                </footer>

            </MobileView>
        </>
    );
}

export default Footer;
