const api = process.env.REACT_APP_API;

//const API_ID = process.env.REACT_APP_API_ID
//const APP_KEY = process.env.REACT_APP_APP_KEY

let token = localStorage.token;
if (!token) {
    token = localStorage.token = Math.random().toString(36).substr(-8);
}


const headers = {
    'Accept': 'application/json',
    'Authorization': token,
    'Content-Type': 'application/json'
}



/*export const getPedido = () =>
    fetch(`${api}/pedido/`, {
        headers
    }).then(res => res.json());
*/



export const getPedido = (origem, codigo) =>
    fetch(`${api}/pedido/${origem}/${codigo}`, {
        headers
    }).then(res => res.json());

export const getPedidonfe = (origem, codigo) =>
    fetch(`${api}/pedidonfe/${origem}/${codigo}`, {
        headers
    }).then(res => res.json());
/*
export const getAllOrdemCarga = (option) =>
    fetch(`${api}/ordemdecarga/${option}`, {
        headers
    }).then(res => res.json());
*/

export const getLocation = (origem, nunota) =>
    fetch(`${api}/location/${nunota}`, {
        headers
    }).then(res => res.json());

export const getPedidoRastreio = (origem, codigo) =>
    fetch(`${api}/${origem}/${codigo}`, {
        headers
    }).then(res => res.json());






export const postDetalhesNF = (entidade) =>
    fetch(`${api}/entrega/certa/guide/`, {
        method: 'POST',
        headers,
        body: JSON.stringify(entidade)
    }).then(res => res.json());



export const postSaveForm = (entidade) =>
    fetch(`${api}/entrega/certa/saveform/`, {
        method: 'POST',
        headers,
        body: JSON.stringify(entidade)
    }).then(res => res.json());






