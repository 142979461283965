import { combineReducers } from 'redux';
import PedidosReducer from './PedidosReducer';
import EntregaCerta from './EntregaCertaReducer'


export default combineReducers({
    PedidosReducer,
    EntregaCerta
})

