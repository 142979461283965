import React from 'react';
import { Button, Icon, Image, Modal, ModalActions, ModalContent } from 'semantic-ui-react';
import { SeparadorLinha } from './styles';

function ModalDetalhes({ open, close, assinatura, foto, mobile }) {

    return (
        <Modal open={open} onClose={close} >
            <ModalContent>
                <table width="100%">
                    {/* <tr>
                        <td colSpan="3" align="center"><h3>Fotos Registradas</h3></td>
                    </tr>
                    <tr>
                        <SeparadorLinha />
                    </tr> */}
                    <tr>
                        <td colspan="3">

                            {mobile ? (
                                <Image.Group size='medium' align='center'>
                                    {foto && foto.map(item => (
                                        <Image src={`data:image/png;base64,${item.fotoclob}`} />
                                    ))}
                                </Image.Group>
                            ) : (

                                <Image.Group size='medium'>
                                    {foto && foto.map(item => (
                                        <Image style={{ width: '32%' }} src={`data:image/png;base64,${item.fotoclob}`} />
                                    ))}
                                </Image.Group>
                            )}

                        </td>
                    </tr>

                    <tr>
                        <td colSpan="3" align="center"><h3>Assinatura</h3></td>
                    </tr>
                    <tr>
                        <SeparadorLinha />
                    </tr>
                    <tr>
                        <td colSpan="3">
                            <table width="100%" border="0">
                                <tr>
                                    <td valign='top' align='center'>

                                        <Image src={`data:image/png;base64,${assinatura}`} />

                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>

                </table>
            </ModalContent>
            <ModalActions>
                <Button onClick={close} negative>
                    <Icon name='remove' />    Fechar
                </Button>
            </ModalActions>
        </Modal>
    );
}

export default ModalDetalhes;
