
import { useState, useEffect } from "react";
import Header from './forrmHeader.js';
import Footer from './formFooter.js';
import { BrowserView, MobileView } from 'react-device-detect';
import { Radio, FormField, TextArea, Checkbox, Button, Segment, Loader } from 'semantic-ui-react';
import { detalhesNFAction, saveFormAction } from '../../../actions/EntregaCertaActions.js'
import { connect } from 'react-redux';



import If from "../../../utils/If.js";
import '../../styles/form.css';
import FormSucess from "./formSucess.js";

function Form({ match, detalhesNFAction, dadosNF, isLoadingBody, saveFormAction, screen, isLeadingSave }) {


    useEffect(() => {
        const guideValue = match.params.guide;
        const origemValue = match.params.origem;
        const urlData = {
            guide: guideValue,
            origem: origemValue === 'A' ? 'E' : origemValue === 'B' ? 'W' : origemValue === 'C' ? 'V' : ''
        }
        detalhesNFAction(urlData);

    }, []);

    useEffect(() => {
        if (dadosNF?.length > 0) {
            if (dadosNF[0]?.resp_end) {
                setSelectedValueEndereco(dadosNF[0].resp_end);
            }

            if (dadosNF[0]?.resticao) {
                setSelectedValueRestricao(dadosNF[0].resticao);
            }

            if (dadosNF[0]?.end_correto) {
                if (dadosNF[0].resp_end !== 'S')
                    setTextAreaEnd(dadosNF[0].end_correto);
            }

            if (dadosNF[0]?.restricao_correto) {
                if (dadosNF[0]?.resticao !== 'N') {
                    setTextAreaRes(dadosNF[0].restricao_correto);
                }

            }

            if (dadosNF[0]?.restricao_horario) {
                setSelectedValueDiasHorarios(dadosNF[0].restricao_horario);
            }

            if (dadosNF[0]?.restricao_horario_correto) {
                if (dadosNF[0]?.restricao_horario !== 'N') {
                    setTextAreaDiasHoras(dadosNF[0].restricao_horario_correto);
                }

            }
        }
    }, [dadosNF]);


    const [selectedValueEndereco, setSelectedValueEndereco] = useState("");
    const [selectedRestricao, setSelectedValueRestricao] = useState("");
    const [selectedDiasHorarios, setSelectedValueDiasHorarios] = useState("");
    const [textAreaEnd, setTextAreaEnd] = useState("");
    const [textAreaRes, setTextAreaRes] = useState("");
    const [textAreaDiasHoras, setTextAreaDiasHoras] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [isSucessoVisible, setIsSucessoVisible] = useState(false);


    function handleChangeEnd(e, { value }) {
        setSelectedValueEndereco(value);
        console.log("Valor selecionado:", value);
    }
    function handleChangeRes(e, { value }) {
        setSelectedValueRestricao(value);
        console.log("Valor selecionado:", value);
    }
    function handleChangeDiasHorarios(e, { value }) {
        setSelectedValueDiasHorarios(value);
        console.log("Valor selecionado:", value);
    }



    function handleSubmit() {

        let isSending = false;
        let isvalido = true;


        console.log(textAreaDiasHoras)
        if (!selectedValueEndereco || !selectedRestricao || !selectedDiasHorarios) {
            setErrorMessage("Erro: Todas as perguntas precisam ser respondidas!");
            isvalido = false;
        }
        if (selectedValueEndereco === "Não" && textAreaEnd === '') {
            setErrorMessage("Erro: O campo de texto não pode estar vazio!");
            isvalido = false;
        }
        if (selectedRestricao === "Sim" && textAreaRes === '') {
            setErrorMessage("Erro: O campo de texto não pode estar vazio!");
            isvalido = false;
        }
        if (selectedDiasHorarios === "Tenho restrições de dias e horários!" && textAreaDiasHoras === '') {
            setErrorMessage("Erro: O campo de texto não pode estar vazio!");
            isvalido = false;
        }


        if (isvalido) {
            // Se não houver erro, limpar a mensagem de erro e continuar
            setErrorMessage("");
        }




        const data = {
            guide: match.params.guide,
            origem: match.params.origem === 'A' ? 'E' : match.params.origem === 'B' ? 'W' : match.params.origem === 'C' ? 'V' : '',
            end: selectedValueEndereco,
            endcorreto: textAreaEnd,
            restricao: selectedRestricao,
            restricaotext: textAreaRes,
            restricaohorario: selectedDiasHorarios === 'Tenho restrições de dias e horários!' ? 'S' : 'N',
            restricaohorariotext: textAreaDiasHoras
        }
        console.log(data);

        if (isvalido && isSending == false) {
            saveFormAction(data).then(response => {
                console.log(response);
                if (response[0].status === "OK") {
                    // console.log()
                    setIsSucessoVisible(true)
                    window.scrollTo({
                        top: 0,
                        behavior: "smooth" // Adiciona um efeito de rolagem suave
                    });
                }
            })
        }



    }

    console.log(isLeadingSave);


    return (
        <>
            <BrowserView>
                <Header />


                <If test={screen == 0}>
                    {(
                        match.params.origem === 'A' ||
                        match.params.origem === 'B' ||
                        match.params.origem === 'C'
                    ) && (
                            <>
                                <div className="container-body">
                                    <div className='container-infos-nota'>
                                        <div>
                                            <p>Pedido: {dadosNF?.[0]?.pedido !== '' ? dadosNF?.[0]?.pedido : ''}</p>
                                        </div>
                                        <div>
                                            <p>NF: {dadosNF?.[0]?.nf !== '' ? dadosNF?.[0]?.nf : ''}</p>
                                        </div>
                                    </div>
                                </div>
                                {isSucessoVisible ? (<FormSucess />) : (
                                    <>
                                        <Segment>

                                            <Loader active={isLoadingBody} />
                                            {
                                                isLoadingBody ? (
                                                    <div className="loading-body"></div>
                                                ) : (
                                                    <div className="container-body">
                                                        <Segment>
                                                            <div className='container-endereco'>
                                                                <span><b>Endereço:</b> {dadosNF?.[0]?.logradouro !== '' ? dadosNF?.[0]?.logradouro : ''}, {dadosNF?.[0]?.numero !== '' ? dadosNF?.[0]?.numero : ''}</span>
                                                                <span><b>Complemento:</b> {dadosNF?.[0]?.complemento !== '' ? dadosNF?.[0]?.complemento : ''}</span>
                                                                <span><b>Bairro:</b> {dadosNF?.[0]?.bairro !== '' ? dadosNF?.[0]?.bairro : ''}</span>
                                                                <span>
                                                                    <b>Cidade/UF:</b> {dadosNF?.[0]?.cidade
                                                                        ? dadosNF[0].cidade.replace(/\/\s*(\w+)/, (_, uf) => `/ ${uf.toUpperCase()}`)
                                                                        : ''}
                                                                </span>                                                            </div>
                                                            <div className='container-confirm-end'>
                                                                <p><b>O endereço de entrega está correto?</b></p>
                                                                <div className="container-radios">
                                                                    <FormField>
                                                                        <Radio
                                                                            label="Sim"
                                                                            name="radioGroup"
                                                                            value="S"
                                                                            checked={selectedValueEndereco === "S"}
                                                                            onChange={handleChangeEnd}
                                                                        />
                                                                    </FormField>
                                                                    <FormField>
                                                                        <Radio
                                                                            label="Não"
                                                                            name="radioGroup"
                                                                            value="N"
                                                                            checked={selectedValueEndereco === "N"}
                                                                            onChange={handleChangeEnd}
                                                                        />
                                                                    </FormField>
                                                                </div>
                                                                <div className="container-textarea">
                                                                    <If test={selectedValueEndereco === 'N'}>
                                                                        <label>Descreva com detalhes o que está errado ou que queira completar no endereço de entrega</label>
                                                                        <TextArea
                                                                            placeholder="Descreva com detalhes o que está errado ou que queira completar no endereço de entrega"
                                                                            name="motivo"
                                                                            maxLength={500}
                                                                            style={{
                                                                                width: '100%', // Faz o TextArea ocupar 100% da largura disponível
                                                                                resize: 'none', // Desabilita o redimensionamento
                                                                                padding: '10px',
                                                                            }}
                                                                            onChange={(e) => setTextAreaEnd(e.target.value)}
                                                                            value={textAreaEnd}
                                                                        />
                                                                        <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end', alignItems: 'center' }}>
                                                                            {textAreaEnd?.length}/500
                                                                        </div>
                                                                    </If>
                                                                </div>

                                                            </div>
                                                        </Segment>
                                                        <Segment>
                                                            <div className='container-confirm-end'>
                                                                <p><b>Existe alguma restrição na sua cidade, condomínio ou no seu endereço para entrada de caminhões ou mesmo alguma dificuldade que possa nos contar?</b></p>
                                                                <div className="container-radios">
                                                                    <FormField>
                                                                        <Radio
                                                                            label="Sim"
                                                                            name="radioGroup1"
                                                                            value="S"
                                                                            checked={selectedRestricao === "S"}
                                                                            onChange={handleChangeRes}
                                                                        />
                                                                    </FormField>
                                                                    <FormField>
                                                                        <Radio
                                                                            label="Não"
                                                                            name="radioGroup1"
                                                                            value="N"
                                                                            checked={selectedRestricao === "N"}
                                                                            onChange={handleChangeRes}
                                                                        />
                                                                    </FormField>

                                                                </div>
                                                                <div className="container-textarea">
                                                                    <If test={selectedRestricao === 'S'}>
                                                                        <label>Descreva tudo que possa ajudar nosso motorista a chegar até você!</label>
                                                                        <TextArea
                                                                            placeholder="Descreva tudo que possa ajudar nosso motorista a chegar até você!"
                                                                            name="motivo"
                                                                            maxLength={500}
                                                                            style={{
                                                                                width: '100%', // Faz o TextArea ocupar 100% da largura disponível
                                                                                resize: 'none', // Desabilita o redimensionamento
                                                                                padding: '10px',
                                                                            }}
                                                                            value={textAreaRes}
                                                                            onChange={(e) => setTextAreaRes(e.target.value)}
                                                                        />
                                                                        <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end', alignItems: 'center' }}>
                                                                            {textAreaRes?.length}/500
                                                                        </div>
                                                                    </If>
                                                                </div>

                                                            </div>
                                                        </Segment>
                                                    </div>
                                                )
                                            }


                                        </Segment>

                                    </>)}
                                <Segment>
                                    <div className="container-body">
                                        <Segment>
                                            <div className="infoEntrega">
                                                <p><b>Nosso horário de entrega é de segunda a sexta-feira, das 8h às 19h, e aos sábados, das 8h às 12h.</b></p>
                                                <p>Avisaremos você por e-mail e WhatsApp quando sairmos do nosso Centro de Distribuição e quando estivermos a caminho do seu endereço. Fique atento e evite que sua entrega seja adiada, devolvida ou sujeita a possíveis taxas extras. Queremos garantir que seu pedido chegue sem transtornos e no prazo.</p>
                                            </div>

                                            {isSucessoVisible ? ('') : (
                                                <div className="checkbox-envio">
                                                    <div className="container-radios">
                                                        <FormField>
                                                            <Radio
                                                                label="Estou ciente!"
                                                                name="radioGroup2"
                                                                value="Estou ciente!"
                                                                checked={selectedDiasHorarios === "N" || selectedDiasHorarios === "Estou ciente!"}
                                                                onChange={handleChangeDiasHorarios}
                                                            />
                                                        </FormField>
                                                        <FormField>
                                                            <Radio
                                                                label="Tenho restrições de dias e horários!"
                                                                name="radioGroup2"
                                                                value="Tenho restrições de dias e horários!"
                                                                checked={selectedDiasHorarios === "S" || selectedDiasHorarios === "Tenho restrições de dias e horários!"}
                                                                onChange={handleChangeDiasHorarios}
                                                            />
                                                        </FormField>

                                                    </div>
                                                    <div className="container-textarea">
                                                        <If test={selectedDiasHorarios === 'Tenho restrições de dias e horários!' || selectedDiasHorarios === 'S'}>
                                                            <label>Descreva suas restrições de dias e horários.</label>
                                                            <TextArea
                                                                placeholder="Descreva suas restrições de dias e horários..."
                                                                name="motivo"
                                                                maxLength={500}
                                                                style={{
                                                                    width: '100%', // Faz o TextArea ocupar 100% da largura disponível
                                                                    resize: 'none', // Desabilita o redimensionamento
                                                                    padding: '10px',
                                                                }}
                                                                onChange={(e) => setTextAreaDiasHoras(e.target.value)}
                                                                value={textAreaDiasHoras}
                                                            />
                                                            <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end', alignItems: 'center' }}>
                                                                {textAreaDiasHoras?.length}/500
                                                            </div>
                                                        </If>
                                                    </div>
                                                    {errorMessage && (
                                                        <div style={{ color: "red", marginBottom: "10px", textAlign: "center" }}>
                                                            {errorMessage}
                                                        </div>
                                                    )}
                                                    <Button color="green" onClick={handleSubmit} disabled={isLeadingSave} loading={isLeadingSave}>Enviar</Button>

                                                </div>
                                            )}
                                        </Segment>
                                    </div>

                                </Segment>

                            </>
                        )}

                </If>

                <If test={screen == 1}>
                    <div className="container-undefined"></div>

                </If>

                <Footer />
            </BrowserView>







            <MobileView>
                <Header />
                <If test={screen == 0}>
                    {(
                        match.params.origem === 'A' ||
                        match.params.origem === 'B' ||
                        match.params.origem === 'C'
                    ) && (
                            <>

                                <div className="container-body-mobile">
                                    <div className='container-infos-nota'>
                                        <div>
                                            <p>Pedido: {dadosNF?.[0]?.pedido !== '' ? dadosNF?.[0]?.pedido : ''}</p>
                                        </div>
                                        <div>
                                            <p>NF: {dadosNF?.[0]?.nf !== '' ? dadosNF?.[0]?.nf : ''}</p>
                                        </div>
                                    </div>
                                </div>
                                {isSucessoVisible ? (<FormSucess />) : (
                                    <>
                                        <Segment>

                                            <Loader active={isLoadingBody} />
                                            {
                                                isLoadingBody ? (
                                                    <div className="loading-body"></div>
                                                ) : (
                                                    <div className="container-body-mobile">
                                                        <Segment>
                                                            <div className='container-endereco-mobile'>
                                                                <span><b>Endereço:</b> {dadosNF?.[0]?.logradouro !== '' ? dadosNF?.[0]?.logradouro : ''}, {dadosNF?.[0]?.numero !== '' ? dadosNF?.[0]?.numero : ''}</span>
                                                                <span><b>Complemento:</b> {dadosNF?.[0]?.complemento !== '' ? dadosNF?.[0]?.complemento : ''}</span>
                                                                <span><b>Bairro:</b> {dadosNF?.[0]?.bairro !== '' ? dadosNF?.[0]?.bairro : ''}</span>
                                                                <span>
                                                                    <b>Cidade/UF:</b> {dadosNF?.[0]?.cidade
                                                                        ? dadosNF[0].cidade.replace(/\/\s*(\w+)/, (_, uf) => `/ ${uf.toUpperCase()}`)
                                                                        : ''}
                                                                </span>                                                            </div>
                                                            <div className='container-confirm-end-mobile'>
                                                                <p><b>O endereço de entrega está correto?</b></p>
                                                                <div className="container-radios">
                                                                    <FormField>
                                                                        <Radio
                                                                            label="Sim"
                                                                            name="radioGroup"
                                                                            value="S"
                                                                            checked={selectedValueEndereco === "S"}
                                                                            onChange={handleChangeEnd}
                                                                        />
                                                                    </FormField>
                                                                    <FormField>
                                                                        <Radio
                                                                            label="Não"
                                                                            name="radioGroup"
                                                                            value="N"
                                                                            checked={selectedValueEndereco === "N"}
                                                                            onChange={handleChangeEnd}
                                                                        />
                                                                    </FormField>
                                                                </div>
                                                                <div className="container-textarea-mobile">
                                                                    <If test={selectedValueEndereco === 'N'}>
                                                                        <label>Descreva com detalhes o que está errado ou que queira completar no endereço de entrega</label>
                                                                        <TextArea
                                                                            placeholder="Descreva com detalhes o que está errado ou que queira completar no endereço de entrega"
                                                                            name="motivo"
                                                                            maxLength={500}
                                                                            style={{
                                                                                width: '100%', // Faz o TextArea ocupar 100% da largura disponível
                                                                                resize: 'none', // Desabilita o redimensionamento
                                                                                padding: '10px',
                                                                            }}
                                                                            onChange={(e) => setTextAreaEnd(e.target.value)}
                                                                            value={textAreaEnd}
                                                                        />
                                                                        <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end', alignItems: 'center' }}>
                                                                            {textAreaEnd?.length}/500
                                                                        </div>
                                                                    </If>
                                                                </div>

                                                            </div>
                                                        </Segment>
                                                        <Segment>
                                                            <div className='container-confirm-end-mobile'>
                                                                <p><b>Existe alguma restrição na sua cidade, condomínio ou no seu endereço para entrada de caminhões ou mesmo alguma dificuldade que possa nos contar?</b></p>
                                                                <div className="container-radios">
                                                                    <FormField>
                                                                        <Radio
                                                                            label="Sim"
                                                                            name="radioGroup1"
                                                                            value="S"
                                                                            checked={selectedRestricao === "S"}
                                                                            onChange={handleChangeRes}
                                                                        />
                                                                    </FormField>
                                                                    <FormField>
                                                                        <Radio
                                                                            label="Não"
                                                                            name="radioGroup1"
                                                                            value="N"
                                                                            checked={selectedRestricao === "N"}
                                                                            onChange={handleChangeRes}
                                                                        />
                                                                    </FormField>

                                                                </div>
                                                                <div className="container-textarea-mobile">
                                                                    <If test={selectedRestricao === 'S'}>
                                                                        <label>Descreva tudo que possa ajudar nosso motorista a chegar até você!</label>
                                                                        <TextArea
                                                                            placeholder="Descreva tudo que possa ajudar nosso motorista a chegar até você!"
                                                                            name="motivo"
                                                                            maxLength={500}
                                                                            style={{
                                                                                width: '100%', // Faz o TextArea ocupar 100% da largura disponível
                                                                                resize: 'none', // Desabilita o redimensionamento
                                                                                padding: '10px',
                                                                            }}
                                                                            value={textAreaRes}
                                                                            onChange={(e) => setTextAreaRes(e.target.value)}
                                                                        />
                                                                        <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end', alignItems: 'center' }}>
                                                                            {textAreaRes?.length}/500
                                                                        </div>
                                                                    </If>
                                                                </div>

                                                            </div>
                                                        </Segment>
                                                    </div>
                                                )
                                            }


                                        </Segment>

                                    </>)}

                                <Segment>
                                    <div className="container-body-mobile">
                                        <Segment>
                                            <div className="infoEntrega">
                                                <p><b>Nosso horário de entrega é de segunda a sexta-feira, das 8h às 19h, e aos sábados, das 8h às 12h.</b></p>
                                                <p>Avisaremos você por e-mail e WhatsApp quando sairmos do nosso Centro de Distribuição e quando estivermos a caminho do seu endereço. Fique atento e evite que sua entrega seja adiada, devolvida ou sujeita a possíveis taxas extras. Queremos garantir que seu pedido chegue sem transtornos e no prazo.</p>
                                            </div>

                                            {isSucessoVisible ? ('') : (
                                                <div className="checkbox-envio">
                                                    <div className="container-radios-mobile">
                                                        <FormField>
                                                            <Radio
                                                                label="Estou ciente!"
                                                                name="radioGroup2"
                                                                value="Estou ciente!"
                                                                checked={selectedDiasHorarios === "N" || selectedDiasHorarios === "Estou ciente!"}
                                                                onChange={handleChangeDiasHorarios}
                                                            />
                                                        </FormField>
                                                        <FormField>
                                                            <Radio
                                                                label="Tenho restrições de dias e horários!"
                                                                name="radioGroup2"
                                                                value="Tenho restrições de dias e horários!"
                                                                checked={selectedDiasHorarios === "S" || selectedDiasHorarios === "Tenho restrições de dias e horários!"}
                                                                onChange={handleChangeDiasHorarios}
                                                            />
                                                        </FormField>

                                                    </div>
                                                    <div className="container-textarea-mobile">
                                                        <If test={selectedDiasHorarios === 'Tenho restrições de dias e horários!' || selectedDiasHorarios === 'S'}>
                                                            <label>Descreva suas restrições de dias e horários.</label>
                                                            <TextArea
                                                                placeholder="Descreva suas restrições de dias e horários..."
                                                                name="motivo"
                                                                maxLength={500}
                                                                style={{
                                                                    width: '100%', // Faz o TextArea ocupar 100% da largura disponível
                                                                    resize: 'none', // Desabilita o redimensionamento
                                                                    padding: '10px',
                                                                }}
                                                                onChange={(e) => setTextAreaDiasHoras(e.target.value)}
                                                                value={textAreaDiasHoras}
                                                            />
                                                            <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end', alignItems: 'center' }}>
                                                                {textAreaDiasHoras?.length}/500
                                                            </div>
                                                        </If>
                                                    </div>
                                                    {errorMessage && (
                                                        <div style={{ color: "red", marginBottom: "10px", textAlign: "center" }}>
                                                            {errorMessage}
                                                        </div>
                                                    )}
                                                    <Button color="green" onClick={handleSubmit} disabled={isLeadingSave} loading={isLeadingSave}>Enviar</Button>

                                                </div>
                                            )}
                                        </Segment>
                                    </div>

                                </Segment>
                            </>
                        )}
                </If>

                <If test={screen == 1}>
                    <div className="container-undefined-mobile"></div>

                </If>

                <Footer />
            </MobileView>
        </>
    );
}


const mapStateToProps = (state) => ({
    dadosNF: state.EntregaCerta.dadosNF,
    isLoadingBody: state.EntregaCerta.isLoadingBody,
    screen: state.EntregaCerta.screen,
    isLeadingSave: state.EntregaCerta.isLeadingSave
})

export default connect(mapStateToProps, {
    detalhesNFAction,
    saveFormAction
}
)(Form);

