import logo from '../../../img/Esplanebranco.png';
import { BrowserView, MobileView } from 'react-device-detect';
import { connect } from 'react-redux';
import '../../styles/formHeader.css';


function Header({ dadosNF }) {
    return (
        <>
            <BrowserView>
                <div className='HeaderLogo'>
                    <img src={logo} alt='#' />
                    <div className='container-titles'>
                        <div className='subtitle'>
                            <p>Nos ajude a realizar sua entrega sem imprevistos!</p>
                        </div>
                        <div className='title'>
                            <p>{dadosNF?.[0]?.nomeparc ? dadosNF?.[0]?.nomeparc : ''}</p>
                        </div>
                    </div>

                </div>
            </BrowserView>

            <MobileView>
                <div className='HeaderLogo-mobile'>
                    <div className='container-titles-mobile'>
                        <img src={logo} alt='#' />

                        <div className='subtitle-mobile'>
                            <p>Nos ajude a realizar sua entrega sem imprevistos!</p>
                        </div>
                    </div>
                    {/* <div className='container-titles'> */}
                    <div className='title-mobile'>
                        <span>{dadosNF?.[0]?.nomeparc ? dadosNF?.[0]?.nomeparc : ''}</span>
                    </div>
                    {/* </div> */}

                </div>

            </MobileView >
        </>
    );
}

const mapStateToProps = (state) => ({
    dadosNF: state.EntregaCerta.dadosNF
})

export default connect(mapStateToProps, {

}
)(Header);
