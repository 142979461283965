import {
    ENTREGACERTA_DETALHES_NF,
    ENTREGACERTA_DETALHES_NF_LOADING,
    ENTREGACERTA_SCREEN,
    ENTREGACERTA_SAVE_LOADING
} from '../actions/ActionsTypes';

const INITIAL_STATE = {
    dadosNF: [],
    isLoadingBody: true,
    screen: 0,
    isLeadingSave: false
}


export default (state = INITIAL_STATE, action) => {
    switch (action.type) {

        case ENTREGACERTA_DETALHES_NF:
            return { ...state, dadosNF: action.payload }

        case ENTREGACERTA_DETALHES_NF_LOADING:
            return { ...state, isLoadingBody: action.payload }

        case ENTREGACERTA_SCREEN:
            return { ...state, screen: action.payload }

        case ENTREGACERTA_SAVE_LOADING:
            return { ...state, isLeadingSave: action.payload }

        default:
            return state;
    }
}