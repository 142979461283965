import Stepper from '@keyvaluesystems/react-stepper';
import React, { useState } from 'react';
import { Icon, Image } from 'semantic-ui-react';
import moment from 'moment';
import If from '../../utils/If';
import "./Stepper.css";

function StepperComponent(props) {

    const [mostrarTodos, setMostrarTodos] = useState(false);

    const styleStep = {
        LineSeparator: () => ({
            backgroundColor: "#34d56a",

        }),
        ActiveNode: () => ({
            backgroundColor: "#4ca7e8",
        }),
        CompletedNode: () => ({
            backgroundColor: "#34d56a",
        }),
        Node: () => ({
            marginLeft: '15px',
            marginRight: '15px',
            width: '40px',
            height: '40px',
        }),
        ActiveLabelDescription: () => ({
            width: '200px',
            textAlign: 'center',
        }),
    }

    function formatDate(date) {
        if (date !== null) {
            return moment(date).format('DD/MM/YYYY');
        } else {
            return '';
        }
    }

    function getCurrentStepIndex(dadosSteps, dados) {
        switch (dadosSteps !== undefined && dados !== undefined) {
            case dadosSteps.dtpagto === null && dadosSteps.dtfatur === null:
                return 1;
            case dadosSteps.dtfatur === null:
                return 2;
            case dados[0].TIPO === 'CD AGUARDANDO':
                return 3;
            case dados[0].TIPO === 'CD':
                return 4;
            case dadosSteps.dtentrega !== null && props.pedidoEntregue !== null:
                return -1;
            default: return 0;
        }
    };

    const getSteps = (dadosSteps, dados, currentStepIndex) => {

        let ultimoARealizarPos = -1;
        for (let i = dados?.length - 1; i >= 0; i--) {
            if (dados[i].TIPO === "A REALIZAR") {
                ultimoARealizarPos = i;
                break;
            }
        }

        const steps = [
            {
                stepLabel: 'Pedido criado',
                stepDescription: dadosSteps.dtneg ? formatDate(dadosSteps.dtneg) : '',
                completed: true,
            },
            {
                stepLabel: dadosSteps.dtpagto ? 'Liberado' : dadosSteps.dtfatur ? 'Liberado' : 'Em Análise',
                stepDescription: dadosSteps.dtpagto ? formatDate(dadosSteps.dtpagto) : dadosSteps.dtfatur ? formatDate(dadosSteps.dtfatur) : 'Seu pedido está em análise cadastral e financeira.',
                //eslint-disable-next-line 
                completed: dadosSteps.dtpagto || dadosSteps.dtfatur && true,

            },
            dadosSteps.dtfatur === null ? {
                stepLabel: dadosSteps.dtfatur ? 'Aguardando Rota' : 'Liberado',
                stepDescription: dadosSteps.dtfatur ? 'Seu pedido está confirmado! Agora é só aguardar a emissão da NF, que será realizada no dia anterior à sua rota' : dadosSteps.dtpagto,
                completed: false,
            } : {
                stepLabel: 'NF Emitida',
                stepDescription: formatDate(dadosSteps.dtfatur),
                completed: dadosSteps.dtfatur && true,
            },
            dados[0].TIPO === 'CD AGUARDANDO' ? {
                stepLabel: dadosSteps.dtfatur ? 'A transportar' : 'NF Emitida',
                stepDescription: dadosSteps.dtfatur ? '' : dadosSteps.dtfatur,
                completed: false,
            } : {
                stepLabel: 'Enviado',
                stepDescription: formatDate(dadosSteps.dtsaidacd),
                completed: dados[0].TIPO === 'CD' ? true : false,
            },
            dadosSteps.dtinicentr === null ? {
                stepLabel: dados[0].TIPO === 'CD' ? 'A Caminho' : 'Enviado',
                stepDescription: dados[0].TIPO === 'CD' && props.orientation !== 'horizontal' ?

                    (
                        <>
                            <If test={dados[0].GUIDE} >
                                <If test={dados[0].TIPO !== 'A CAMINHO'}>
                                    <If test={dados[0].TIPO !== 'ENTREGANDO'}>
                                        <If test={dados[0].TIPO !== 'FINALIZADA'}>
                                            <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                                                <Image src='../img/atencao.png' style={{ width: '60px', height: '59px' }} />
                                                <div >
                                                    <p style={{ color: 'orange', fontSize: '15px', fontStyle: 'italic' }}>Atenção!! Você ainda não confirmou seu endereço, mas ainda dá tempo! Clique <a href={`https://rastreamento.esplane.com.br/entrega/${dados[0].GUIDE}/B`} target="_blank"> aqui</a> e nos ajude a realizar sua entrega sem imprevistos.</p>
                                                </div>
                                            </div>
                                        </If>
                                    </If>
                                </If>
                            </If>
                            <div>

                                {dados.map((item, index) => {
                                    const isARealizar = item.TIPO === 'A REALIZAR';
                                    const isUltimoARealizar = index === ultimoARealizarPos;
                                    const isPenultimoARealizar = index === ultimoARealizarPos - 1;
                                    const deveEsconder = item.TIPO.includes('FINALIZADA') || (isARealizar && !isUltimoARealizar && !mostrarTodos);

                                    // Verificando se o penúltimo item não é de tipo "A CAMINHO", "ENTREGANDO", "PRIMEIRA A CAMINHO" ou "FINALIZADA"
                                    const mostrarBotao = isPenultimoARealizar && !(dados[ultimoARealizarPos - 1].TIPO === "A CAMINHO" ||
                                        dados[ultimoARealizarPos - 1].TIPO === "ENTREGANDO" ||
                                        dados[ultimoARealizarPos - 1].TIPO === "PRIMEIRA A CAMINHO" ||
                                        dados[ultimoARealizarPos - 1].TIPO === "FINALIZADA");

                                    // Adicionamos o botão antes do último "A REALIZAR"
                                    if (mostrarBotao) {
                                        return (
                                            <>
                                                <div
                                                    key={index}
                                                    className="step"
                                                    style={{ display: deveEsconder ? "none" : "flex" }}
                                                >

                                                    <div
                                                        className={
                                                            item.TIPO === "A CAMINHO" || item.TIPO === 'ENTREGANDO' || item.TIPO === "PRIMEIRA A CAMINHO"
                                                                ? "step-circle-current"
                                                                : isARealizar
                                                                    ? "step-circle-wait"
                                                                    : "step-circle"
                                                        }
                                                    >
                                                        {index}
                                                    </div>
                                                    <div
                                                        className={`step-line ${item.TIPO === "A CAMINHO" || isARealizar || item.TIPO === "ENTREGANDO" || item.TIPO === "PRIMEIRA A CAMINHO"
                                                            ? "step-line-gray"
                                                            : ""
                                                            }`}
                                                        style={{ animationDelay: `${index * 0.5}s` }}
                                                    ></div>
                                                    <div className="step-description" dangerouslySetInnerHTML={{ __html: item.DESCRICAO }}></div>
                                                </div>
                                                {/* Botão Ver mais */}
                                                <p style={{ all: 'unset', cursor: 'pointer', color: '#56a6dd', fontWeight: 'bold', fontSize: '14px', marginLeft: '20px' }} onClick={() => setMostrarTodos(!mostrarTodos)}>
                                                    {mostrarTodos ? "Ver menos" : "Ver mais"}
                                                </p>
                                            </>
                                        );
                                    }

                                    return (

                                        <div
                                            key={index}
                                            className="step"
                                            style={{ display: deveEsconder ? "none" : "flex", marginTop: '20px' }}
                                        >

                                            <div
                                                className={
                                                    item.TIPO === "A CAMINHO" || item.TIPO === 'ENTREGANDO' || item.TIPO === "PRIMEIRA A CAMINHO"
                                                        ? "step-circle-current"
                                                        : isARealizar
                                                            ? "step-circle-wait"
                                                            : "step-circle"
                                                }
                                            >
                                                {index}
                                            </div>
                                            <div
                                                className={`step-line ${item.TIPO === "A CAMINHO" || isARealizar || item.TIPO === "ENTREGANDO" || item.TIPO === "PRIMEIRA A CAMINHO"
                                                    ? "step-line-gray"
                                                    : ""
                                                    }`}
                                                style={{ animationDelay: `${index * 0.5}s` }}
                                            ></div>
                                            <div className="step-description" dangerouslySetInnerHTML={{ __html: item.DESCRICAO }}></div>
                                        </div>
                                    );
                                })}
                            </div >
                        </>
                    ) : ''


                ,
                completed: false,
            } : {
                stepLabel: 'A Caminho',
                //eslint-disable-next-line
                stepDescription: dados[0].TIPO === 'CD' && props.orientation !== 'horizontal' && !dados[dados.length - 1]?.TIPO.includes("FINALIZADA") ? dados.map((item, index) => (
                    <div>
                        <div
                            className={
                                item.TIPO === "A CAMINHO" || item.TIPO === 'ENTREGANDO' || item.TIPO === "PRIMEIRA A CAMINHO"
                                    ? "step-circle-current"
                                    : item.TIPO === "A REALIZAR"
                                        ? "step-circle-wait"
                                        : "step-circle"
                            }
                        >
                            {index}
                        </div>
                        <div
                            className={`step-line-mobile ${item.TIPO === "A CAMINHO" || item.TIPO === "A REALIZAR" || item.TIPO === "ENTREGANDO" || item.TIPO === "PRIMEIRA A CAMINHO"
                                ? "step-line-mobile-gray"
                                : ""
                                }`}
                            style={{ animationDelay: `${index * 0.5}s` }} // Adiciona atraso progressivo
                        ></div>
                        <div className="step-description" style={{ marginLeft: '40px', marginTop: '-30px', marginBottom: '20px' }} dangerouslySetInnerHTML={{ __html: item.DESCRICAO }}></div>
                    </div>)) : '',
                //eslint-disable-next-line
                completed: dadosSteps.checkout ? true : false,
            },
            {
                stepLabel: dadosSteps.dtentrega ? 'Entrega Finalizada' : 'Entregue',
                stepDescription: dadosSteps.checkout ? formatDate(dadosSteps.checkout) : '',
                //eslint-disable-next-line
                completed: dadosSteps.checkout ? true : false,
            },
        ];
        return steps;
    };

    const currentStepIndex = getCurrentStepIndex(props.dadosSteps, props.dados);
    const steps = getSteps(props.dadosSteps, props.dados, currentStepIndex);


    return (
        <Stepper
            orientation={props.orientation}
            showDescriptionsForAllSteps
            labelPosition={props.orientation === 'horizontal' ? 'bottom' : 'right'}
            styles={styleStep}
            renderNode={(step, stepIndex) => (
                stepIndex === 0 && step.completed === false ? (<div style={{ alignItems: 'center', justifyContent: 'center', marginLeft: 2 }}><Icon name="cart arrow down" size='large' /></div>) :
                    stepIndex === 1 && step.completed === false ? (<div style={{ alignItems: 'center', justifyContent: 'center', marginLeft: 5 }}><Icon name="search" size='large' /></div>) :
                        stepIndex === 2 && step.completed === false ? (<div style={{ alignItems: 'center', justifyContent: 'center', marginLeft: 5 }}><Icon name="box" size='large' /></div>) :
                            stepIndex === 3 && step.completed === false ? (<div style={{ alignItems: 'center', justifyContent: 'center', marginLeft: 5 }}><Icon name={props.dadosSteps.ETAPA_QUATRO === 'NF Emitida' ? "file alternate" : "dolly"} size='large' /></div>) :
                                stepIndex === 4 && step.completed === false ? (<div style={{ alignItems: 'center', justifyContent: 'center', marginLeft: 2 }}><Icon name="shipping fast" size='large' /></div>) :
                                    stepIndex === 5 && step.completed === false && (<div style={{ alignItems: 'center', justifyContent: 'center', marginLeft: 2 }}><Icon name="clipboard check" size='large' /></div>)
            )}
            steps={steps}
            currentStepIndex={currentStepIndex}
        />
    );
}

export default StepperComponent;