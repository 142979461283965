import Header from './forrmHeader.js';
import Footer from './formFooter.js';
import { BrowserView, MobileView } from 'react-device-detect';
import '../../styles/formSucess.css';

function FormSucess() {
    return (
        <>
            <BrowserView>

                <div className="container-body">
                    <div className='Title-sucess'>
                        <h1>Formulario enviado com sucesso!</h1>
                        <img className="gif" src="https://images.squarespace-cdn.com/content/v1/6209fc508f791e729abec7d0/18641903-a848-4a3a-a0a3-c9e2ddaa15c4/02-lottie-tick-01-instant-2.gif" alt="" />
                    </div>
                </div>

            </BrowserView>

            <MobileView>

                <div className="container-body-mobile">
                    <div className='Title-sucess'>
                        <h1>Formulario enviado com sucesso!</h1>
                        <img className="gif" src="https://images.squarespace-cdn.com/content/v1/6209fc508f791e729abec7d0/18641903-a848-4a3a-a0a3-c9e2ddaa15c4/02-lottie-tick-01-instant-2.gif" alt="" />
                    </div>
                </div>

            </MobileView>

        </>
    );
}

export default FormSucess;