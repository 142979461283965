import React, { useEffect, useRef, useState } from 'react';
import { withRouter } from 'react-router';
import moment from 'moment';
import 'moment/locale/pt-br';


import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { Header, Segment, Loader, Image, Container, Grid } from 'semantic-ui-react'

import If from '../../utils/If';

import { pedidosListRastreioAction } from '../../actions/PedidosActions';
import StepperComponent from './StepperComponent';
import { ContainerHeader } from './styles';
import "./Stepper.css";
import Mapa from './Mapa';
import { BrowserView, MobileView } from 'react-device-detect';
import ModalDetalhes from './ModalDetalhes';
import socketIOClient from "socket.io-client";
import CardEntrega from './CardEntrega';
import { SkeletonNomeHeader, SkeletonPedidoHeader, SkeletonPedidoHeaderMobile, SkeletonStatus, SkeletonStepper, SkeletonStepperMobile } from './Skeletons';

function Rastreamento(props) {

    const { dados, isloading_rastreio } = props;

    const [markerPosition1, setMarkerPosition1] = useState(null);
    const [tempoAtualização, setTempoAtualização] = useState();
    // const [distanciaEstimada, setDistanciaEstimada] = useState();
    //eslint-disable-next-line
    const [pedidoEntregue, setPedidoEntregue] = useState(null);
    const [openModal, setOpenModal] = useState();
    const intervalRef = useRef(null);
    const [mostrarTodos, setMostrarTodos] = useState(false);
    const [distancia, setDistancia] = useState(null);

    let ultimoARealizarPos = -1;
    for (let i = dados.DataSource4?.length - 1; i >= 0; i--) {
        if (dados.DataSource4[i].TIPO === "A REALIZAR") {
            ultimoARealizarPos = i;
            break;
        }
    }


    //alterar para dev para rodar localmente
    const socket = socketIOClient(process.env.REACT_APP_API);


    // const handleMoveMarker = () => {
    //     // Simula um cálculo para alterar a posição do marcador
    //     const newLat = markerPosition1.lat + 0.001; // Aumenta a latitude
    //     const newLng = markerPosition1.lng + 0.001; // Aumenta a longitude
    //     setMarkerPosition1({ lat: newLat, lng: newLng });
    // };

    // console.log(dados.DataSource4)


    useEffect(() => {
        props.pedidosListRastreioAction(props.origem, props.codigo);

        //eslint-disable-next-line
        if (isloading_rastreio === false && dados.DataSource1?.[0].dtentrega === null || dados.DataSource1?.[0].dtocorrencia === null) {
            // setMarkerPosition1({ lat: Number.parseFloat(dados.DataSource1?.[0].from_lat), lng: Number.parseFloat(dados.DataSource1?.[0].from_lng) })


            getLocationBySocket();
            //eslint-disable-next-line
        } else if (isloading_rastreio === false && dados.DataSource1?.[0].dtentrega !== null || dados.DataSource1?.[0].dtocorrencia !== null) {
            setMarkerPosition1(null)
        }

        socket.on("SocketGetLocation", (data) => {
            //   setIsLoading(false);

            if (data !== null) {

                setMarkerPosition1({ lat: Number.parseFloat(data.lat), lng: Number.parseFloat(data.lng) });

                const time = moment(data.datelastposition).locale('pt-br').from(moment().locale('pt-br'));
                setTempoAtualização(time);

                console.log('MARKER POSITION:', Number.parseFloat(data.lat), Number.parseFloat(data.lng))
                console.log('MARKER TO:', Number.parseFloat(data.to_lat), Number.parseFloat(data.to_lng))

                // const distancia_estimada = (data.total_distance - data.cur_distance) / 1000;
                // setDistanciaEstimada(distancia_estimada < 0 ? 0 : distancia_estimada.toFixed(2));

                if (intervalRef.current) {
                    clearInterval(intervalRef.current);
                }

                // Inicia um novo intervalo para atualizar o tempo a cada segundo
                intervalRef.current = setInterval(() => {
                    const time = moment(data.datelastposition).locale('pt-br').from(moment());
                    setTempoAtualização(time);
                }, 1000);
            } else {
                setMarkerPosition1(null);
                // setPedidoEntregue(true)
                // props.pedidosListRastreioAction(props.origem, props.codigo);
            }

        });

        async function getLocationBySocket() {

            const nunota = dados.DataSource1?.[0].nunota

            // await getLocation(nunota)

            socket.emit('SocketLocationConnect', { nunota });


        }
        //eslint-disable-next-line
    }, [isloading_rastreio]);

    const handleAbrirModal = () => {
        setOpenModal(true)
    }
    const handleFecharModal = () => {
        setOpenModal(false);
    }

    function calcularDistancia(lat1, lon1, lat2, lon2) {
        const R = 6371; // Raio da Terra em km
        const toRad = (valor) => (valor * Math.PI) / 180; // Converter graus para radianos

        const dLat = toRad(lat2 - lat1);
        const dLon = toRad(lon2 - lon1);

        const a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(toRad(lat1)) * Math.cos(toRad(lat2)) *
            Math.sin(dLon / 2) * Math.sin(dLon / 2);

        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

        return (R * c).toFixed(2); // Distância em km com duas casas decimais
    }

    useEffect(() => {
        const resultado = calcularDistancia(markerPosition1?.lat, markerPosition1?.lng, dados.DataSource1?.[0].to_lat, dados.DataSource1?.[0].to_lng);
        setDistancia(resultado);
        //eslint-disable-next-line
    }, [distancia, markerPosition1]);


    const guide = dados.DataSource4?.[0].GUIDE
    return (
        <>
            <BrowserView>
                <ModalDetalhes open={openModal} close={handleFecharModal} assinatura={dados.DataSource1?.[0].assinaturaclob !== undefined ? dados.DataSource1?.[0].assinaturaclob : null} foto={dados.DataSource2 ? dados.DataSource2 : null} />
                <ContainerHeader>
                    <Container>
                        <Grid verticalAlign='middle'>
                            <Grid.Column width={4}>
                                {props.origem === 'voceconstroi' ? (
                                    <Image src='../img/vcc.png' />
                                ) : (
                                    <Image src='../img/logo.png' width={200} height={80} />
                                )}
                            </Grid.Column>
                            <Grid.Column width={8}>
                                <br />
                                <Header as='h2' textAlign='center'>
                                    {isloading_rastreio ? (


                                        <SkeletonNomeHeader />
                                    ) : (
                                        <>
                                            <Header.Content>
                                                {dados.DataSource1?.[0].nomeparc}
                                            </Header.Content>
                                            <Header.Subheader>
                                                CPF/CNPJ: {dados.DataSource1?.[0].cpf}
                                            </Header.Subheader>
                                        </>
                                    )}
                                </Header>
                            </Grid.Column>

                            <Grid.Column width={4}>
                                <br />
                                <Header as='h3' textAlign='center'>
                                    {isloading_rastreio ? (
                                        <SkeletonPedidoHeader />
                                    ) : (

                                        <Header.Content>
                                            Pedido: {dados.DataSource1?.[0].pedidofbits}
                                            <Header.Subheader>
                                                NF: {dados.DataSource1?.[0].numnota ? dados.DataSource1?.[0].numnota : '-'}
                                            </Header.Subheader>
                                            <Header.Subheader>
                                                OC: {dados.DataSource4?.[1] ? dados.DataSource4?.[1].ORDEMCARGA : '-'}
                                            </Header.Subheader>
                                        </Header.Content>
                                    )}
                                </Header>
                            </Grid.Column>

                        </Grid>
                    </Container>
                </ContainerHeader>

                <Container style={{ marginBottom: '100px' }} >
                    <If test={isloading_rastreio}>
                        <div style={{ display: 'flex', flex: 1, flexDirection: 'column', justifyContent: 'center', }}>
                            <Segment>
                                <h3>Informações do Rastreio:</h3>
                                <SkeletonStepper />
                                <SkeletonStatus />
                            </Segment>
                        </div>
                    </If>




                    <If test={!isloading_rastreio}>
                        <Segment>

                            <h3>Informações do Rastreio:</h3>

                            <StepperComponent orientation="horizontal" dadosSteps={dados.DataSource1?.[0]} dados={dados.DataSource4} pedidoEntregue={pedidoEntregue} />

                            {dados.DataSource4?.length > 0 && (
                                <div style={{ padding: '20px 70px' }}>
                                    <div className="stepper-container">
                                        <div className={dados.DataSource4[dados.DataSource4.length - 1]?.TIPO.includes("FINALIZADA")
                                            ? "arrow-entregue"
                                            : dados.DataSource4[dados.DataSource4.length - 1]?.TIPO.includes("A CAMINHO")
                                                && !dados.DataSource4[0]?.TIPO.includes("CD AGUARDANDO")
                                                || dados.DataSource4[dados.DataSource4.length - 1]?.TIPO.includes("A REALIZAR")
                                                && !dados.DataSource4[0]?.TIPO.includes("CD AGUARDANDO")
                                                || dados.DataSource4[dados.DataSource4.length - 1]?.TIPO.includes("ENTREGANDO")
                                                && !dados.DataSource4[0]?.TIPO.includes("CD AGUARDANDO") ? "arrow" : "arrow-transportar"}></div>
                                        {dados.DataSource4[dados.DataSource4.length - 1]?.TIPO.includes("FINALIZADA") ? (
                                            <>
                                                {//eslint-disable-next-line
                                                    (dados.DataSource1?.[0].checkout || pedidoEntregue) ? (
                                                        <CardEntrega handleAbrirModal={handleAbrirModal} dados={dados.DataSource1?.[0]} />
                                                    ) : (
                                                        <div style={{ display: 'flex', flex: 1, width: '100%', marginTop: 50 }}>

                                                            <Loader active inline='centered'>Carregando informações...</Loader>

                                                        </div>
                                                    )}

                                                {//eslint-disable-next-line
                                                    (dados.DataSource1?.[0].checkout && dados.DataSource1?.[0].to_lat && dados.DataSource1?.[0].to_lng || pedidoEntregue === true) && (

                                                        <div style={{ display: 'flex', flex: 1, width: '100%', justifyContent: 'center', marginTop: '30px' }}>

                                                            <Mapa marker={markerPosition1} dadosMapa={dados.DataSource1?.[0]} />

                                                        </div>
                                                    )}

                                            </>
                                        ) : (
                                            <>
                                                {dados.DataSource1?.[0].dtinicentr !== null && dados.DataSource1?.[0].dtentrega === null && dados.DataSource1?.[0].docrecebedor === null && dados.DataSource1?.[0].dtocorrencia === null && (

                                                    !markerPosition1 ? (
                                                        <div style={{ display: 'flex', flex: 1, width: '100%', marginTop: 50 }}>

                                                            <Loader active inline='centered'>Carregando mapa...</Loader>

                                                        </div>
                                                    ) :

                                                        (
                                                            <div style={{ display: 'flex', flexDirection: 'column', flex: 1, width: '100%', alignItems: 'center', marginTop: '30px' }}>

                                                                <div><b>*Esta é uma rota prevista para a sua entrega, porém ela pode sofrer alterações de acordo com o motorista</b></div>
                                                                <div>
                                                                    <b style={{ color: '#8c8c8c', marginRight: '10px' }}>Última atualização {tempoAtualização}.</b>
                                                                    <b style={{ color: '#8c8c8c' }}>Faltam: {distancia}km até o seu endereço. </b>
                                                                </div>

                                                                <Mapa marker={markerPosition1} dadosMapa={dados.DataSource1?.[0]} />

                                                            </div>
                                                        )

                                                )}

                                                <div>
                                                    <If test={guide} >
                                                        <If test={dados.DataSource4[dados.DataSource4.length - 1]?.TIPO !== 'A CAMINHO'}>
                                                            <If test={dados.DataSource4[dados.DataSource4.length - 1]?.TIPO !== 'ENTREGANDO'}>
                                                                <If test={dados.DataSource4[dados.DataSource4.length - 1]?.TIPO !== 'FINALIZADA'}>
                                                                    <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                                                                        <Image src='../img/atencao.png' style={{ width: '60px', height: '59px' }} />
                                                                        <div >
                                                                            <p style={{ color: 'orange', fontSize: '15px', fontStyle: 'italic' }}>Atenção!! Você ainda não confirmou seu endereço, mas ainda dá tempo! Clique <a href={`https://rastreamento.esplane.com.br/entrega/${guide}/B`} target="_blank"> aqui</a> e nos ajude a realizar sua entrega sem imprevistos.</p>
                                                                        </div>
                                                                    </div>
                                                                </If>
                                                            </If>
                                                        </If>
                                                    </If>


                                                    {dados.DataSource4.map((item, index) => {
                                                        const isARealizar = item.TIPO === 'A REALIZAR';
                                                        const isUltimoARealizar = index === ultimoARealizarPos;
                                                        const isPenultimoARealizar = index === ultimoARealizarPos - 1;
                                                        const deveEsconder = item.TIPO.includes('FINALIZADA') || (isARealizar && !isUltimoARealizar && !mostrarTodos);

                                                        // Verificando se o penúltimo item não é de tipo "A CAMINHO", "ENTREGANDO", "PRIMEIRA A CAMINHO" ou "FINALIZADA"
                                                        const mostrarBotao = isPenultimoARealizar && !(dados.DataSource4[ultimoARealizarPos - 1].TIPO === "A CAMINHO" ||
                                                            dados.DataSource4[ultimoARealizarPos - 1].TIPO === "ENTREGANDO" ||
                                                            dados.DataSource4[ultimoARealizarPos - 1].TIPO === "PRIMEIRA A CAMINHO" ||
                                                            dados.DataSource4[ultimoARealizarPos - 1].TIPO === "FINALIZADA");

                                                        // Adicionamos o botão antes do último "A REALIZAR"
                                                        if (mostrarBotao) {
                                                            return (
                                                                <>
                                                                    <div
                                                                        key={index}
                                                                        className="step"
                                                                        style={{ display: deveEsconder ? "none" : "flex" }}
                                                                    >
                                                                        <div
                                                                            className={
                                                                                item.TIPO === "A CAMINHO" || item.TIPO === 'ENTREGANDO' || item.TIPO === "PRIMEIRA A CAMINHO"
                                                                                    ? "step-circle-current"
                                                                                    : isARealizar
                                                                                        ? "step-circle-wait"
                                                                                        : "step-circle"
                                                                            }
                                                                        >
                                                                            {index}
                                                                        </div>
                                                                        <div
                                                                            className={`step-line ${item.TIPO === "A CAMINHO" || isARealizar || item.TIPO === "ENTREGANDO" || item.TIPO === "PRIMEIRA A CAMINHO"
                                                                                ? "step-line-gray"
                                                                                : ""
                                                                                }`}
                                                                            style={{ animationDelay: `${index * 0.5}s` }}
                                                                        ></div>
                                                                        <div className="step-description" dangerouslySetInnerHTML={{ __html: item.DESCRICAO }}></div>
                                                                    </div>
                                                                    {/* Botão Ver mais */}
                                                                    {dados.DataSource4?.length > 2 && (

                                                                        <p style={{ all: 'unset', cursor: 'pointer', color: '#56a6dd', fontWeight: 'bold', fontSize: '14px', marginLeft: '20px' }} onClick={() => setMostrarTodos(!mostrarTodos)}>
                                                                            {mostrarTodos ? "Ver menos" : "Ver mais"}
                                                                        </p>
                                                                    )}
                                                                </>
                                                            );
                                                        }

                                                        return (
                                                            <div
                                                                key={index}
                                                                className="step"
                                                                style={{ display: deveEsconder ? "none" : "flex", marginTop: '20px' }}
                                                            >
                                                                <div
                                                                    className={
                                                                        item.TIPO === "A CAMINHO" || item.TIPO === 'ENTREGANDO' || item.TIPO === "PRIMEIRA A CAMINHO"
                                                                            ? "step-circle-current"
                                                                            : isARealizar
                                                                                ? "step-circle-wait"
                                                                                : "step-circle"
                                                                    }
                                                                >
                                                                    {index}
                                                                </div>
                                                                <div
                                                                    className={`step-line ${item.TIPO === "A CAMINHO" || isARealizar || item.TIPO === "ENTREGANDO" || item.TIPO === "PRIMEIRA A CAMINHO"
                                                                        ? "step-line-gray"
                                                                        : ""
                                                                        }`}
                                                                    style={{ animationDelay: `${index * 0.5}s` }}
                                                                ></div>
                                                                <div className="step-description" dangerouslySetInnerHTML={{ __html: item.DESCRICAO }}></div>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </div>
                            )}

                        </Segment>

                    </If>

                </Container >
            </BrowserView >

            {/* ------------------------------------ SEPARAÇÃO BROWSER / MOBILE --------------------------------------------- */}

            <MobileView MobileView >
                <ModalDetalhes open={openModal} close={handleFecharModal} assinatura={dados.DataSource1?.[0].assinaturaclob !== undefined ? dados.DataSource1?.[0].assinaturaclob : null} foto={dados.DataSource2 ? dados.DataSource2 : null} mobile />
                <ContainerHeader>
                    <Container>
                        <Grid verticalAlign='middle'>
                            <Grid.Row centered width={4}>
                                <Grid.Column width={7}>
                                    <div style={{ marginLeft: 20 }}>
                                        {props.origem === 'voceconstroi' ? (
                                            <Image src='../img/vcc.png' />
                                        ) : (
                                            <Image src='../img/logo.png' width={150} height={57} />
                                        )}
                                    </div>
                                </Grid.Column>
                                <Grid.Column width={7} floated='right'>
                                    <Header as='h3' textAlign='center'>
                                        {isloading_rastreio ? (
                                            <SkeletonPedidoHeaderMobile />
                                        ) : (

                                            <Header.Content>
                                                Pedido: {dados.DataSource1?.[0].pedidofbits}
                                                <Header.Subheader>
                                                    NF: {dados.DataSource1?.[0].numnota ? dados.DataSource1?.[0].numnota : '-'}
                                                </Header.Subheader>
                                                <Header.Subheader>
                                                    OC: {dados.DataSource4?.[1] ? dados.DataSource4?.[1].ORDEMCARGA : '-'}
                                                </Header.Subheader>
                                            </Header.Content>
                                        )}
                                    </Header>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row centered width={8}>
                                <Header as='h3' textAlign='center'>
                                    {isloading_rastreio ? (


                                        <SkeletonNomeHeader />
                                    ) : (
                                        <div style={{ padding: '10px 20px' }}>
                                            <Header.Content>
                                                {dados.DataSource1?.[0].nomeparc}
                                            </Header.Content>
                                            <Header.Subheader>
                                                CPF/CNPJ: <br />{dados.DataSource1?.[0].cpf}
                                            </Header.Subheader>
                                        </div>
                                    )}

                                </Header>
                            </Grid.Row>

                        </Grid>
                    </Container>
                </ContainerHeader>

                <Container>

                    <If test={isloading_rastreio}>
                        <div style={{ display: 'flex', flex: 1, flexDirection: 'column', justifyContent: 'center' }}>
                            <Segment>
                                <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <h3>Informações do Rastreio:</h3>
                                </div>
                                <SkeletonStepperMobile />
                            </Segment>
                        </div>
                    </If>




                    <If test={!isloading_rastreio}>
                        <Segment>

                            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <h3>Informações do Rastreio</h3>
                            </div>

                            {(dados.DataSource1?.[0].checkout || pedidoEntregue) && (
                                <CardEntrega mobile dados={dados.DataSource1?.[0]} handleAbrirModal={handleAbrirModal} />
                            )}

                            {dados.DataSource1?.[0].dtinicentr !== null && dados.DataSource1?.[0].dtentrega === null && pedidoEntregue !== true && (

                                !markerPosition1 ? (
                                    <div style={{ display: 'flex', flex: 1, width: '100%', marginTop: 50 }}>

                                        <Loader active inline='centered'>Carregando mapa...</Loader>

                                    </div>
                                ) : (

                                    <div style={{ display: 'flex', flexDirection: 'column', flex: 1, width: '100%', alignItems: 'center', marginTop: '30px' }}>

                                        <div style={{ textAlign: 'center', marginBottom: 30 }}><b>*Esta é uma rota prevista para a sua entrega, porém ela pode sofrer alterações de acordo com o motorista</b></div>
                                        <div>
                                            <b style={{ color: '#8c8c8c', textAlign: 'center', marginBottom: 20 }}>Última atualização {tempoAtualização}.</b>
                                        </div>

                                        <b style={{ color: '#8c8c8c', marginBottom: 30, textAlign: 'center' }}>Faltam: {distancia}km até o seu endereço.</b>

                                        {markerPosition1 && <Mapa marker={markerPosition1} dadosMapa={dados.DataSource1?.[0]} />}

                                    </div>

                                )
                            )}

                            {//eslint-disable-next-line
                                (dados.DataSource1?.[0].checkout && dados.DataSource1?.[0].to_lat && dados.DataSource1?.[0].to_lng || pedidoEntregue) && (
                                    <div style={{ display: 'flex', flex: 1, width: '100%', justifyContent: 'center', marginTop: '30px' }}>

                                        <Mapa marker={markerPosition1} dadosMapa={dados.DataSource1?.[0]} />

                                    </div>
                                )}
                            <StepperComponent dadosSteps={dados.DataSource1?.[0]} dados={dados.DataSource4} pedidoEntregue={pedidoEntregue} />

                        </Segment>

                    </If>

                </Container >
            </MobileView >
        </>
    );
}

Rastreamento.defaultProps = {
    origem: ''
    , codigo: ''
}
Rastreamento.propTypes = {
    origem: PropTypes.string.isRequired
    , codigo: PropTypes.string.isRequired

}

const mapStateToProps = state => ({
    dados: state.PedidosReducer.dados_rastreio
    , isloading_rastreio: state.PedidosReducer.isloading_rastreio
})

export default withRouter(connect(mapStateToProps, {
    // pedidosListAction,
    pedidosListRastreioAction
})(Rastreamento));