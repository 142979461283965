import * as Api from '../utils/API';
import {
    ENTREGACERTA_DETALHES_NF,
    ENTREGACERTA_DETALHES_NF_LOADING,
    ENTREGACERTA_SCREEN,
    ENTREGACERTA_SAVE_LOADING
} from '../actions/ActionsTypes';


export const detalhesNFAction = (item) => (dispatch) =>
    new Promise(function (resolve, reject) {

        dispatch({ type: ENTREGACERTA_DETALHES_NF_LOADING, payload: true });
        dispatch({ type: ENTREGACERTA_SAVE_LOADING, payload: false });
        console.log(item.origem)

        if (item.origem === 'W' || item.origem === 'E' || item.origem === 'V') {
            console.log('caiu aqui')
            Api.postDetalhesNF(item).then(response => {

                if (response.length == 0) {
                    dispatch({ type: ENTREGACERTA_SCREEN, payload: 1 });
                }
                dispatch({ type: ENTREGACERTA_DETALHES_NF, payload: response });
                dispatch({ type: ENTREGACERTA_DETALHES_NF_LOADING, payload: false });
                console.log(response.length)


                resolve([])


            }).catch(error => {
                reject(error);
            })

        } else {
            dispatch({ type: ENTREGACERTA_SCREEN, payload: 1 });
            resolve([])
        }

    });



export const saveFormAction = (item) => (dispatch) =>
    new Promise(function (resolve, reject) {

        dispatch({ type: ENTREGACERTA_DETALHES_NF_LOADING, payload: true });
        dispatch({ type: ENTREGACERTA_SAVE_LOADING, payload: true });
        Api.postSaveForm(item).then(response => {
            dispatch({ type: ENTREGACERTA_SAVE_LOADING, payload: false });
            resolve(response)
        }).catch(error => {
            dispatch({ type: ENTREGACERTA_SAVE_LOADING, payload: false });
            reject(error);
        })

    });

