import React, { useEffect, useRef, useState } from 'react';
import { compose, withProps } from "recompose";
import {
    withScriptjs,
    withGoogleMap,
    GoogleMap,
    Marker,
    DirectionsRenderer,
} from "react-google-maps";
/* global google */

const Mapa = compose(
    withProps({
        googleMapURL:
            "https://maps.googleapis.com/maps/api/js?key=AIzaSyDkYZGyXi8QjtnjZOwfJj-qg5uilkPpSzs&v=3.exp&libraries=geometry,drawing,places&language=pt-BR",
        loadingElement: <div style={{ height: `100%` }} />,
        containerElement: <div style={{ height: `350px`, width: `80%` }} />,
        mapElement: <div style={{ height: `100%` }} />
    }),
    withScriptjs,
    withGoogleMap,
)(props => {

    const [directions, setDirections] = useState(null);
    const [zoomChanged, setZoomChanged] = useState(false);
    const [vehicle, setVehicle] = useState({
        rotation: 0,
        position: null
    });

    const mapRef = useRef(null);

    const calculateRotation = (prevPos, nextPos) => {
        return google.maps.geometry.spherical.computeHeading(
            new google.maps.LatLng(prevPos.lat(), prevPos.lng()),
            new google.maps.LatLng(nextPos.lat(), nextPos.lng())
        );
    };

    useEffect(() => {
        if (props.marker || props.dadosMapa) {
            const newPosition = props.marker
                ? new google.maps.LatLng(props.marker)
                : new google.maps.LatLng(props.dadosMapa.to_lat, props.dadosMapa.to_lng);

            setVehicle(prevVehicle => {
                const rotation = prevVehicle.position
                    ? calculateRotation(prevVehicle.position, newPosition)
                    : 0;

                // Verifica se a nova posição é diferente da anterior
                if (
                    !prevVehicle.position ||
                    prevVehicle.position.lat() !== newPosition.lat() ||
                    prevVehicle.position.lng() !== newPosition.lng()
                ) {
                    return {
                        rotation,
                        position: newPosition,
                    };
                }
                return prevVehicle; // Evita re-renderização desnecessária
            });
        }
    }, [props.marker, props.dadosMapa]);

    useEffect(() => {
        const DirectionsService = new google.maps.DirectionsService();

        if (props.marker || props.dadosMapa) {
            DirectionsService.route(
                {
                    origin: props.marker
                        ? new google.maps.LatLng(props.marker)
                        : new google.maps.LatLng(props.dadosMapa.to_lat, props.dadosMapa.to_lng),
                    destination: new google.maps.LatLng(props.dadosMapa.to_lat, props.dadosMapa.to_lng),
                    travelMode: google.maps.TravelMode.DRIVING,
                },
                (result, status) => {
                    if (status === google.maps.DirectionsStatus.OK) {
                        setDirections(result);
                    } else {
                        console.error(`error fetching directions ${result}`);
                    }
                }
            );
        }
        //eslint-disable-next-line 
    }, [props.dadosMapa.to_lat, props.dadosMapa.to_lng, props.marker]);

    const getRotatedImage = (imageUrl, rotation) => {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.onload = () => {
                const canvas = document.createElement("canvas");
                const ctx = canvas.getContext("2d");

                // Define o tamanho do canvas
                canvas.width = img.width;
                canvas.height = img.height;

                // Move o canvas para o centro para que a rotação seja em torno do centro
                ctx.translate(img.width / 2, img.height / 2);

                // Aplica a rotação
                ctx.rotate(rotation * Math.PI / 180);

                // Desenha a imagem rotacionada no canvas
                ctx.drawImage(img, -img.width / 2, -img.height / 2);

                // Cria uma URL com a imagem rotacionada e a retorna
                resolve(canvas.toDataURL());
            };
            img.onerror = reject;
            img.src = imageUrl;
        });
    };

    useEffect(() => {
        if (vehicle.rotation !== 0) {
            getRotatedImage("/img/caminhao-branco.svg", vehicle.rotation)
                .then((rotatedImageUrl) => {
                    setVehicle({
                        ...vehicle,
                        icon: rotatedImageUrl, // Usar a imagem rotacionada no marcador
                    });
                })
                .catch((error) => console.error("Erro ao rotacionar a imagem:", error));
        }
        //eslint-disable-next-line 
    }, [vehicle.rotation]);

    return (
        <GoogleMap
            ref={mapRef}
            onZoomChanged={() => setZoomChanged(true)}
            defaultOptions={{
                fullscreenControl: false,
                streetViewControl: false,
                mapTypeControl: false,
                rotateControl: false,
                scaleControl: false,
                mapTypeId: google.maps.MapTypeId.ROADMAP,
                styles: [
                    { featureType: "administrative.land_parcel", elementType: "labels", stylers: [{ visibility: "off" }] },
                    { featureType: "poi", elementType: "labels.text", stylers: [{ visibility: "off" }] },
                    { featureType: "poi.business", stylers: [{ visibility: "off" }] },
                    { featureType: "poi.park", elementType: "labels.text", stylers: [{ visibility: "off" }] },
                    { featureType: "all", stylers: [{ saturation: 20 }] },
                ]
            }}
        >
            <DirectionsRenderer
                directions={directions}
                options={{
                    suppressMarkers: true, // Não mostrar marcador de direção
                    preserveViewport: zoomChanged
                }}
            />

            {/* Exibir marcador do veículo apenas quando `props.marker` ou `props.dadosMapa` estiverem definidos */}
            {props.marker || props.dadosMapa ? (
                <Marker
                    position={vehicle.position || new google.maps.LatLng(props.marker)}
                    icon={{
                        url: vehicle.icon || '/img/caminhao-branco.svg', // Usa a imagem rotacionada ou a imagem padrão
                        scaledSize: new google.maps.Size(60, 60),
                        origin: new google.maps.Point(0, 0),
                        anchor: new google.maps.Point(20, 25),
                    }}
                />
            ) : null}

            {/* Mostrar o marcador de destino apenas quando a posição é fornecida */}
            {props.dadosMapa && (
                <Marker
                    position={new google.maps.LatLng(props.dadosMapa.to_lat, props.dadosMapa.to_lng)}
                    icon={'/img/pedido-entregue.png'}
                />
            )}
        </GoogleMap>
    );
});

export default Mapa;
